import request from '@/utils/request'
import { ReFormatQueryStr } from '@/utils/formatquerystr'


//表单信息
export function FormxShowForminfo(mid) {

    return request({
        url: '/form/data/forminfo/' + mid,
        method: 'get',
    })

}

export function FormxShowList(mid, query) {

    var data = {
        mid: mid,
        page: query.pageNum,
        psize: query.pageSize,
        qstr: {}
    }

    var tjson = ReFormatQueryStr(query)

    data.qstr = JSON.stringify(tjson)

    return request({

        url: '/form/data/list/' + mid,
        method: 'post',
        data: data
    })

}

//查询一条数据
export function FormxShowGet(mid, id) {

    return request({
        url: '/form/data/get/' + mid + "?id=" + id,
        method: 'get',
    })

}

//保存一条数据
export function FormxShowSavedata(mid, id, data) {
    var url = ""
    if (id == null) {
        url = "/form/data/add/" + mid
    } else {
        url = "/form/data/save/" + mid + "?dataid=" + id
    }
    data["mid"] = mid
    data["dataid"] = id

    return request({
        url: url,
        method: 'post',
        data: data
    })

}

//删除数据
export function FormxShowDel(mid, ids) {
    var data = {
        mid,
        ids
    }

    return request({
        url: "/form/data/del/" + mid,
        method: 'post',
        data: data
    })

}
//导入数据
export function FormDataImport(mid, rows, opts) {
    var data = {
        mid,
        options: JSON.stringify(opts),
        rows: JSON.stringify(rows)
    }

    return request({
        url: "/form/data/import/" + mid,
        method: 'post',
        data: data
    })

}

//获取下载连接
export function FormDataDownloadUrl(mid, query, fieldlist) {
    var data = {
        page: query.pageNum,
        psize: query.pageSize,
        qstr: {},
        fieldlist: Array.isArray(fieldlist) ? fieldlist.join("|") : fieldlist
    }

    var tjson = ReFormatQueryStr(query)
    data.qstr = JSON.stringify(tjson)

    var parata = []
    for (let i in data) {
        parata.push(i + "=" + data[i])
    }


    return `/form/data/download/${mid}?` + parata.join("&");


}
//操作记录
export function FormxOprloglist(mid, query, logtype) {
    console.log(mid, query)
    var data = {
        page: query.pageNum,
        psize: query.pageSize,
        logtype: logtype,
        qstr: {}
    }

    var tjson = ReFormatQueryStr(query)

    data.qstr = JSON.stringify(tjson)
    return request({
        url: '/form/formdata/oprloglist/' + mid,
        method: 'post',
        data: data
    })

}

//筛选保存模板
export function FormxSavedataFilterTpl(data) {

    return request({
        url: '/form/model/savedatafiltertpl',
        method: 'post',
        data: data
    })

}
//获取保存模板
export function FormxGetdataFilterTpl(query) {
    var data = {
        page: query.page,
        psize: query.psize,
        qstr: {}
    }

    var tjson = ReFormatQueryStr(query)

    data.qstr = JSON.stringify(tjson)
    return request({
        url: '/form/model/datafiltertpllist',
        method: 'post',
        data: data
    })

}