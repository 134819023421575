import request from '@/utils/request'
export function StatBpm(query) {
  return request({
    url: '/bpm/stat',
    method: 'get',
    params: query
  })
}
// 保存签名
export function SaveBpmSign(data) {
  return request({
    url: '/bpm/sign/save',
    method: 'post',
    data: data
  })
}

// 获取签名
export function CurrentBpmSign() {
  return request({
    url: '/bpm/sign/current',
    method: 'get'
  })
}
